import axios from "axios";

var baseUrl = "https://d.video-cdn.net/play/player/";

class VideoService {
    static getVideoURL(videoID, playerID) {
        return new Promise((resolve, reject) => {
            (async() => {
                try {
                    const res = await axios.get(baseUrl + playerID + "/video/" + videoID);
                    resolve(
                        res.data.videoSources.html["720p"][0].source
                    )
                } catch (err) {
                    reject(err);
                }
            })()
        })
    }
}

export default VideoService;