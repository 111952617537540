<template>
  <div class="headerBar">
    <div class="headerInnerContainer">
        <img src="/SpringerHealthcare_IME_wide.png">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>