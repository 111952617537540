<template>
  <div class="footerBar">
    <div class="footerInnerContainer">
        <div class="linkContainer">{{initialPreferences.projectName}}&nbsp;|&nbsp;
        <a href="#">About</a>&nbsp;|&nbsp;
        <a href="#">Contact</a>&nbsp;|&nbsp;
        <a href="#">Legal</a>&nbsp;|&nbsp;
        <a href="#">etc.</a>
        </div>
        <img src="https://springerhealthcare.com/wp-content/uploads/thegem-logos/logo_134d4b66f5f2b65ab331ba75e0caa571_1x.png">

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>