import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay, faPause, faSearch, faFilePowerpoint, faVolumeMute, faVolumeUp, faVolumeDown, faCompressArrowsAlt, faExpand, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

library.add(faPlay)
library.add(faPause)
library.add(faSearch)
library.add(faFilePowerpoint)
library.add(faVolumeMute)
library.add(faVolumeUp)
library.add(faVolumeDown)
library.add(faCompressArrowsAlt)
library.add(faExpand)
library.add(faCheck)
library.add(faTimes)
Vue.component('fa-icon', FontAwesomeIcon)