<template>
  <div
    id="videoContainer"
    ref="videoContainer"
    v-bind:class="isFullScreened ? 'fullScreened' : ''"
  >
    <div class="videoSeeker">
      <div class="playBtn">
        <div class="playCircle"></div>
        <div :class="preventVideoControlsInteraction ? 'dimmed' : ''">
          <fa-icon
            :icon="['fas', 'play']"
            @click="play"
            v-bind:style="
              videoPlaying == true ? { display: 'none' } : { display: 'block' }
            "
          ></fa-icon>
          <fa-icon
            :icon="['fas', 'pause']"
            @click="pause"
            v-bind:style="
              videoPlaying == false ? { display: 'none' } : { display: 'block' }
            "
          ></fa-icon>
        </div>
      </div>
      <div class="stackedTitleSeeker">
        <div class="titleText">
          {{
            initialPreferences.PrimaryProjectTitle == ""
              ? initialPreferences.SecondaryProjectTitle
              : initialPreferences.PrimaryProjectTitle
          }}
        </div>
        <div class="seeker">
          <div class="barContainer">
            <div
              class="bar"
              v-bind:style="{ left: currentProgressBarPosition + '%' }"
            ></div>
          </div>
          <div class="timetext">{{ curVideoTime }}</div>
        </div>
      </div>
      <div class="contentButtons">
        <div class="searchButton">
          <fa-icon :icon="['fas', 'search']"></fa-icon>
        </div>
        <div class="slideButtonButton">
          <fa-icon
            :icon="['fas', 'file-powerpoint']"
            @click="toggleSlideInChild"
          ></fa-icon>
        </div>
      </div>
    </div>
    <div class="videoInnerContainer">
      <div class="videoControlsAndVideo">
        <video
          ref="ActualVideo"
          crossorigin="anonymous"
          type="video/mp4"
          class="videodiv"
          playsinline
          preload="auto"
          @ended="VideoEnded"
          @click="togglePlayPause"
        ></video>
        <div
          class="initialOverlay"
          :style="initialOverlayVisible ? 'display: flex' : 'display:none'"
          @click="hideInitialOverlay"
        >
          <div class="primaryTitle">
            {{ initialPreferences.PrimaryProjectTitle }}
          </div>
          <div class="secondaryTitle">
            {{ initialPreferences.SecondaryProjectTitle }}
          </div>
          <fa-icon :icon="['fas', 'play']" class="playIcon"></fa-icon>
          <div class="clickToPlayText">Click Play To Start</div>
        </div>
        <div
          class="videoControlsContent"
          ref="videoControlsContent"
          :style="
            controlsSeen
              ? preventVideoControlsInteraction
                ? { display: 'none' }
                : { display: 'flex' }
              : { display: 'none' }
          "
        >
          <div class="upperContent"></div>
          <div
            class="progressBarConatiner"
            ref="progressBarConatiner"
            @click="seekerClicked"
            @mousedown="seekerMouseDown"
          >
            <div class="progressLineContainer">
              <div class="progressbarBase"></div>
              <div
                class="progressbarProgress"
                v-bind:style="
                  'transform: translateX(' + currentProgressBarPosition + '%)'
                "
              ></div>
            </div>
            <div class="progressbarCircleContiner">
              <div
                class="progressbarCircle"
                v-bind:style="
                  'left:' + (currentProgressBarPosition + 100) + '%'
                "
              ></div>
            </div>
          </div>
          <div class="controlsConatiner">
            <div class="leftConainer">
              <fa-icon
                :icon="['fas', 'play']"
                @click="play"
                v-bind:style="
                  videoPlaying == true
                    ? { display: 'none' }
                    : { display: 'block' }
                "
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'pause']"
                @click="pause"
                v-bind:style="
                  videoPlaying == false
                    ? { display: 'none' }
                    : { display: 'block' }
                "
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'volume-up']"
                @click="muteVideo"
                v-bind:style="
                  curVolume >= 0.5 && isMuted == false
                    ? { display: 'block' }
                    : { display: 'none' }
                "
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'volume-down']"
                @click="muteVideo"
                v-bind:style="
                  curVolume < 0.5 && curVolume != 0 && isMuted == false
                    ? { display: 'block' }
                    : { display: 'none' }
                "
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'volume-mute']"
                @click="unmuteVideo"
                v-bind:style="
                  isMuted || curVolume == 0
                    ? { display: 'block' }
                    : { display: 'none' }
                "
              ></fa-icon>
              <div
                class="volumeSlideContainer"
                ref="volumeSlideContainer"
                @click="volumeSliderClicked"
                @mousedown="volumeSliderMouseDown"
              >
                <div class="volumeLineContainer">
                  <div class="voulmebarBase"></div>
                  <div
                    class="voulmebarProgress"
                    v-bind:style="
                      'transform:translateX(' + (curVolume * 100 - 100) + '%)'
                    "
                  ></div>
                </div>
                <div class="volumeCircleContainer">
                  <div
                    class="volumebarCircle"
                    v-bind:style="'left:' + curVolume * 100 + '%'"
                  ></div>
                </div>
              </div>
              <div class="timetext">
                {{ curVideoTime }} / {{ curVideoFullTime }}
              </div>
            </div>
            <div class="rightConainer">
              <fa-icon :icon="['fas', 'search']"></fa-icon>

              <fa-icon
                :icon="['fas', 'file-powerpoint']"
                @click="toggleSlideInChild"
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'expand']"
                @click="gofullScreen"
                v-bind:style="
                  isFullScreened == true
                    ? { display: 'none' }
                    : { display: 'block' }
                "
              ></fa-icon>
              <fa-icon
                :icon="['fas', 'compress-arrows-alt']"
                @click="nofullScreen"
                v-bind:style="
                  isFullScreened == true
                    ? { display: 'block' }
                    : { display: 'none' }
                "
              ></fa-icon>
            </div>
          </div>
        </div>
        <div
          class="videoLoadingCircle"
          v-bind:style="
            isVideoBuffering == true ? { display: 'flex' } : { display: 'none' }
          "
        >
          <div class="loader"></div>
        </div>
      </div>
      <div class="videoOverlayContent">
        <OverlayContent ref="OverlayContent"></OverlayContent>
      </div>
    </div>
  </div>
</template>

<script>
import VideoService from "../VideoSourceProvider.js";
export default {
  components: {
    OverlayContent: () =>
      import(/* webpackChunkName: "overlay-content" */ "./overlay-content"),
  },
  async mounted() {
    window.videoID = this.initialPreferences.videoID;
    window.addEventListener("resize", () => {
      this.resize();
    });
    window.addEventListener("orientationchange", () => {
      this.orientationChanged();
    });
    this.$refs.ActualVideo.addEventListener("waiting", () => {
      this.isVideoBuffering = true;
    });
    this.$refs.ActualVideo.addEventListener("canplay", () => {
      this.isVideoBuffering = false;
    });
    this.$refs.ActualVideo.addEventListener("loadedmetadata", () => {
      var fullTime = this.$refs.ActualVideo.duration;
      var timeText = (fullTime / (60 * 60)).toFixed(0) + ":";
      fullTime = fullTime % (60 * 60);
      timeText += this.padDigits((fullTime / 60).toFixed(0), 2) + ":";
      fullTime = fullTime % 60;
      timeText += this.padDigits(fullTime.toFixed(0), 2);
      this.curVideoFullTime = timeText;
    });

    if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
      this.$gtag.query("event", "VideoPlayer", {
        data: new Date(),
        event_category: "Initialisation",
        project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
        project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
        device: this.initialPreferences.deviceDetails,
        value: "LoadedAndReady",
      });
    }
  },
  async created() {
    document.addEventListener("beforeunload", this.beforeUnload);
    var sourceURL = await VideoService.getVideoURL(
      this.initialPreferences.videoID,
      this.initialPreferences.playerID
    );
    this.$refs.ActualVideo.innerHTML +=
      '<source src="' + sourceURL + '" type="video/mp4">';
  },
  methods: {
    beforeUnload() {
      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        this.$gtag.query("event", "BrowserClosing", {
          data: new Date(),
          event_category: "Ending",
          project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
          project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
          device: this.initialPreferences.deviceDetails,
          value: this.curVideoTime,
        });
      }
    },
    play() {
      this.lastPlayStartTime = this.$refs.ActualVideo.currentTime;
      this.$refs.ActualVideo.play();
      this.videoPlaying = true;
      this.playertextTimeout = setInterval(() => {
        this.setTimeText();
      }, this.timeTextChangeInterval);
      window.addEventListener("mousemove", this.resetControlsTimeout);
      this.resetControlsTimeout();
    },
    resetControlsTimeout() {
      clearTimeout(this.controlsTimeoutFunc);
      this.controlsSeen = true;
      this.controlsTimeoutFunc = setTimeout(() => {
        this.controlsSeen = false;
      }, this.controlsTimeout);
    },
    VideoEnded() {
      this.pause();
    },
    pause() {
      this.addTotalViewedTime();
      this.$refs.ActualVideo.pause();
      this.videoPlaying = false;
      clearTimeout(this.playertextTimeout);
      clearTimeout(this.controlsTimeoutFunc);
      this.controlsSeen = true;
      window.removeEventListener("mousemove", this.resetControlsTimeout);
    },
    addTotalViewedTime() {
      this.totalPlayTime +=
        this.$refs.ActualVideo.currentTime - this.lastPlayStartTime;
      this.lastPlayStartTime = this.$refs.ActualVideo.currentTime;
    },
    setTimeText() {
      var curTime = this.$refs.ActualVideo.currentTime;
      this.$refs.OverlayContent.runMethods(curTime);
      this.currentProgressBarPosition =
        (curTime / this.$refs.ActualVideo.duration).toFixed(2) * 100 - 100;
      var timeText = parseInt(curTime / (60 * 60)) + ":";
      curTime = curTime % (60 * 60);
      timeText += this.padDigits(parseInt(curTime / 60), 2) + ":";
      curTime = curTime % 60;
      timeText += this.padDigits(parseInt(curTime), 2);
      this.curVideoTime = timeText;
      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        if(parseInt(curTime)%10==0)
          this.addTotalViewedTime();
        var percent = parseInt(
          (this.totalPlayTime / this.$refs.ActualVideo.duration)*10
        );
        if (percent > this.lastLoggedPercent) {
          this.$gtag.event("VideoPlayer", {
            data: new Date(),
            event_category: "PlayTime",
            project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
            project_secondarytitle: this.initialPreferences
              .SecondaryProjectTitle,
            device: this.initialPreferences.deviceDetails,
            value: percent * 10 + "%",
          });
          this.lastLoggedPercent = percent;
        }
      }
    },
    resize() {
      var newisMobileView = window.innerWidth <= window.mobileWidth;
      this.isMobileView = newisMobileView;
    },
    padDigits(number, digits) {
      return (
        Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number
      );
    },
    orientationChanged() {
      if (window.orientation != 90 && window.orientation != -90)
        alert(
          "User Interface is made with mobile deivce in mind. Please make sure the device is in potrait mode."
        );
    },
    toggleSlideInChild() {
      this.$refs.OverlayContent.toggleSlide();
    },
    gofullScreen() {
      if (this.$refs.videoContainer.requestFullscreen) {
        this.$refs.videoContainer.requestFullscreen();
      } else if (this.$refs.videoContainer.webkitRequestFullscreen) {
        this.$refs.videoContainer.webkitRequestFullscreen();
      } else if (this.$refs.videoContainer.msRequestFullscreen) {
        this.$refs.videoContainer.msRequestFullscreen();
      }
      this.isFullScreened = true;
    },
    nofullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.isFullScreened = false;
    },
    volumeSliderClicked(e) {
      this.addTotalViewedTime();
      this.setVolumeBasedOnMouseOffset(e.offsetX);
    },
    muteVideo() {
      this.$refs.ActualVideo.muted = true;
      this.isMuted = true;
    },
    unmuteVideo() {
      this.$refs.ActualVideo.muted = false;
      if (this.curVolume == 0) this.curVolume = 0.4;
      this.isMuted = false;
    },
    seekerClicked(e) {
      this.setSeekerBasedOnMouseOffset(e.offsetX, false);
    },
    volumeSliderMouseDown(e) {
      this.addTotalViewedTime();
      document.addEventListener("mouseup", this.volumeSliderMouseUp);
      document.addEventListener("mousemove", this.volumeSliderMouseMove);
      this.setVolumeBasedOnMouseOffset(e.offsetX);
      this.pauseEvent(e);
    },
    volumeSliderMouseMove(e) {
      this.setVolumeBasedOnMouseOffset(
        e.x - this.$refs.volumeSlideContainer.getBoundingClientRect().x
      );
    },
    volumeSliderMouseUp() {
      document.removeEventListener("mousemove", this.volumeSliderMouseMove);
      document.removeEventListener("mouseup", this.volumeSliderMouseUp);
    },
    setVolumeBasedOnMouseOffset(offset) {
      var value = offset / 80; // 80 voulme bar length
      if (value < 0) {
        value = 0;
      } else if (value >= 1) {
        value = 1;
      }
      var that = this;
      that.$refs.ActualVideo.volume = value;
      requestAnimationFrame(function () {
        that.curVolume = that.$refs.ActualVideo.volume;
        that.isMuted = false;
      });
    },
    pauseEvent(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    },
    seekerMouseDown(e) {
      this.addTotalViewedTime();
      document.addEventListener("mouseup", this.seekerMouseUp);
      document.addEventListener("mousemove", this.seekerMouseMove);
      this.setSeekerBasedOnMouseOffset(e.offsetX, true);
      this.pauseEvent(e);
    },
    seekerMouseMove(e) {
      this.setSeekerBasedOnMouseOffset(
        e.x - this.$refs.progressBarConatiner.getBoundingClientRect().x,
        true
      );
    },
    seekerMouseUp() {
      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        this.$gtag.event("VideoPlayer", {
          data: new Date(),
          event_category: "Seeked",
          project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
          project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
          device: this.initialPreferences.deviceDetails,
          value: "true",
        });
      }
      document.removeEventListener("mousemove", this.seekerMouseMove);
      document.removeEventListener("mouseup", this.seekerMouseUp);
    },
    setSeekerBasedOnMouseOffset(offset, isMouseMove) {
      this.seekTo(
        (offset /
          this.$refs.progressBarConatiner.getBoundingClientRect().width) *
          this.$refs.ActualVideo.duration,
        isMouseMove
      );
      requestAnimationFrame(this.setTimeText);
    },
    togglePlayPause(e) {
      if (e.target != this.$refs.ActualVideo) return;
      window.addEventListener("mousemove", this.resetControlsTimeout);
      this.resetControlsTimeout();
      this.videoPlaying = !this.videoPlaying;
      if (this.videoPlaying) this.play();
      else this.pause();
    },
    hideInitialOverlay() {
      this.initialOverlayVisible = false;
      this.controlsSeen = true;
      this.isVideoBuffering = false;
      this.play();
      if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
        this.$gtag.event("VideoPlayer", {
          data: new Date(),
          event_category: "User interaction",
          project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
          project_secondarytitle: this.initialPreferences.SecondaryProjectTitle,
          device: this.initialPreferences.deviceDetails,
          value: "Playing",
        });
      }
    },
    seekTo(time, isMouseMove) {
      var computedTime = this.$refs.OverlayContent.checkforChanges(
        this.$refs.ActualVideo.currentTime,
        time
      );
      if (computedTime != null) {
        this.$refs.ActualVideo.currentTime = computedTime;
        if (isMouseMove) {
          this.seekerMouseUp();
        }
      } else {
        this.$refs.ActualVideo.currentTime = time;
      }
      if (isMouseMove == false) {
        if (this.initialPreferences.googleAnalyticsTrackingCode != null) {
          this.$gtag.event("VideoPlayer", {
            data: new Date(),
            event_category: "Seeked",
            project_primarytitle: this.initialPreferences.PrimaryProjectTitle,
            project_secondarytitle: this.initialPreferences
              .SecondaryProjectTitle,
            device: this.initialPreferences.deviceDetails,
            value: "true",
          });
        }
      }
    },
    stopControlInteraction() {
      this.preventVideoControlsInteraction = true;
    },
    allowControlInteraction() {
      this.preventVideoControlsInteraction = false;
    },
  },
  data() {
    return {
      videoPlaying: false,
      curVideoTime: "0:00:00",
      curVideoFullTime: "0:00:00",
      timeTextChangeInterval: 500,
      currentProgressBarPosition: -100,
      isMobileView: window.innerWidth <= window.mobileWidth,
      playertextTimeout: null,
      isVideoBuffering: true,
      isFullScreened: false,
      curVolume: 1,
      isMuted: false,
      controlsTimeout: 3000,
      controlsSeen: false,
      initialOverlayVisible: true,
      preventVideoControlsInteraction: false,
      lastPlayStartTime: 0,
      totalPlayTime: 0,
      lastLoggedPercent: 0,
    };
  },
};
</script>
