<template>
<div class="menContainer">
    <div class="menuHeader" :style="initialPreferences.menuItems.length==0?'display:none':'display:flex'">
        <div v-for="(menuItem,index) in initialPreferences.menuItems" @click="menuItemClicked(index)" :key="index" :class="menuItem.isActive?'menuItem active':'menuItem'" >{{menuItem.type}}</div>
    </div>
    <div class="menuContentContainer" ref="menuContentContainer">
        <div id="menuContentItem" v-for="(menuItem,indexx) in initialPreferences.menuItems" :key="indexx">
        <div class="menuContent" v-if="menuItem.type=='Welcome'"><div class="welcomeContainer"><div class="welcomeHeader">{{menuItem.text}}</div><div class="welcomeContent">{{menuItem.content}}</div></div></div>
        <div class="menuContent" v-else>
            <div style="display:flex;flex-direction:column;width:100vw;">
                <div style="height:150px;background:red;"></div>
                <div style="height:150px;background:red;"></div>
                <div style="height:150px;background:red;"></div>
                <div style="height:150px;background:red;"></div>
                <div style="height:150px;background:red;"></div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
        curActiveTab:-1
        }
    },
    created(){
        },
    mounted(){
        this.menuItemClicked(0);
        window.addEventListener('resize', ()=>{this.resize();});
    },
    methods:{
        menuItemClicked(index){
            console.log(index);
            if(this.curActiveTab==-1){
                this.$refs.menuContentContainer.children[index].classList="animateIntoViewFromLeft";
            }
            else if(index<this.curActiveTab){
                this.$refs.menuContentContainer.children[index].classList="animateIntoViewFromLeft";
                this.$refs.menuContentContainer.children[this.curActiveTab].classList="animateOutofViewToRight";
                this.initialPreferences.menuItems[this.curActiveTab].isActive=false;
            }
            else if(index>this.curActiveTab){
                this.$refs.menuContentContainer.children[index].classList="animateIntoViewFromRight";
                this.$refs.menuContentContainer.children[this.curActiveTab].classList="animateOutofViewToLeft";
                this.initialPreferences.menuItems[this.curActiveTab].isActive=false;
            }
            this.$refs.menuContentContainer.style.height=this.$refs.menuContentContainer.children[index].getBoundingClientRect().height+"px";
            this.curActiveTab=index;
            this.initialPreferences.menuItems[this.curActiveTab].isActive=true;
        },
        resize(){
            this.$refs.menuContentContainer.style.height=this.$refs.menuContentContainer.children[this.curActiveTab].getBoundingClientRect().height+"px";
        }
    }
}
</script>
