<template>
  <div id="app">
    <app-header></app-header>
    <app-videoContent></app-videoContent>
    <app-menuContent v-if="initialPreferences.menuItems.length>0"></app-menuContent>
    <app-footer v-if="initialPreferences.footerVisible"></app-footer>
  </div>
</template>

<script>
import menuContent from './components/menu-content'
import videoContent from './components/video-content'
import header from './components/header'
import footer from './components/footer'

export default {
  name: 'App',
  components: {
    'app-menuContent':menuContent,
    'app-videoContent':videoContent,
    'app-header':header,
    'app-footer':footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
